@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400&display=swap);
.App {
  font-family: sans-serif;
  text-align: center;
  flex-grow: 1;
}

body{
  background-color: #FFBA00;
}

.header, .footer{
  background: repeating-linear-gradient(
  45deg,
  rgba(0, 0, 0, 0),
  rgba(0, 0, 0, 0) 30px,
  rgba(0, 0, 0, 1) 30px,
  rgba(0, 0, 0, 1) 60px
  );
  width:100%;
  height: 10vh;
  /* margin-bottom: 50px; */
  top: 0px;
  position:relative;
}

p{
  font-family: 'Source Sans Pro';
}

.footer{
  bottom: 0px;
  margin-bottom: 0px;
  top: auto;
  /* position: absolute; */
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 768px){
  
  .ttsText{
    font-family: Source Sans Pro;
    font-weight: 900;
    font-size: 27px;
    opacity: 0.09;
    position: absolute;
    top: 12vh;
    width: 45vw;
    left: 2.5vw;
    z-index: 0;
    max-height: 80vh;
    overflow-y: hidden;
    text-align: left;
  }
  
}


